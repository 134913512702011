import React from 'react';
import constants from '../constants';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { prefectures } from '../constants/address';
import ShrineList from '../components/ShrineList';
import BlogList from '../components/BlogList';
import Title from '../components/Title';
import SubTitle, { SubTitleTypes } from '../components/SubTitle';

export default ({ location, pageContext }) => {
  const area = prefectures.find(item => item.id === pageContext.area);
  return (
    <Layout>
      <Seo
        title={`${area.name}の神社と記事 | ヒナタビ`}
        description={`神社／パワースポット検索サイト・ヒナタビで掲載している${area.name}の神社と記事一覧です`}
        href={location.href}
      />

      <Title>{area.name}</Title>

      <SubTitle type={SubTitleTypes.shrine} />
      <ShrineList type={constants.articleTypes.area} value={area.id} />

      <SubTitle type={SubTitleTypes.article} />
      <BlogList type={constants.articleTypes.area} value={area.id} />
    </Layout>
  );
};
